// core
import React from "react";
import { useTranslation } from "react-i18next";
import { useWindowSize } from "react-use";

// store

// components

import AppButton from "../../../components/atoms/Button";
import WeldCardImg from "../../../assets/images/weld-card.png";
import WeldCardSub1Img from "./../../../assets/images/weld-card-sub-1.png";
import WeldCardSub2Img from "./../../../assets/images/weld-card-sub-2.png";
import WeldBannerArrowDown from "../../../components/icons/WeldBannerArrowDown";
import WeldBannerArrowUp from "../../../components/icons/WeldBannerArrowUp";

// styles
import "./index.scss";
import { MEDIA } from "../../../utils/constants";

const WeldBanner = ({ data, variables }) => {
  // store
  const { t } = useTranslation();
  const { width } = useWindowSize();
  return (
    <div className="weld-banner">
      <div className="app__wrapper">
        <div className="weld-banner__grid">
          <div
            className="weld-banner__content"
            data-aos="fade-up"
            data-aos-delay="900"
          >
            <div className="weld__title">{data.title}</div>

            {data.blue_description_part_1 && (
              <div
                className="weld-banner__subtitle weld-banner__subtitle--1"
                dangerouslySetInnerHTML={{
                  __html: data.blue_description_part_1,
                }}
              />
            )}

            {width > MEDIA.M1024 && (
              <>
                {variables.weldmoney_link?.value && (
                  <div className="weld-banner__actions">
                    <AppButton
                      className="app-button--fill-3 weld-banner__btn"
                      href={variables.weldmoney_link.value}
                      target="_blank"
                    >
                      {t("actions.openCryptoCardShort")}
                    </AppButton>
                  </div>
                )}
                {/*<div*/}
                {/*  className="weld-banner__text"*/}
                {/*  dangerouslySetInnerHTML={{*/}
                {/*    __html: data.description,*/}
                {/*  }}*/}
                {/*/>*/}
              </>
            )}
          </div>
          <div className="weld-banner__imgs">
            <img
              src={WeldCardSub1Img}
              className="weld-banner__subimg-1"
              data-aos="fade-up"
              data-aos-offset="0"
              data-aos-delay="1800"
            />
            <WeldBannerArrowDown
              className="weld-banner__arrow-down"
              data-aos="fade-up"
              data-aos-offset="0"
              data-aos-delay="1600"
            />
            <img
              src={data.image}
              className="weld-banner__img"
              data-aos="fade-up"
              data-aos-offset="0"
              data-aos-delay="1400"
            />
            <img
              src={WeldCardSub2Img}
              className="weld-banner__subimg-2"
              data-aos="fade-up"
              data-aos-offset="0"
              data-aos-delay="1800"
            />
            <WeldBannerArrowUp
              className="weld-banner__arrow-up"
              data-aos="fade-up"
              data-aos-offset="0"
              data-aos-delay="1600"
            />

            {data.warning && (
                <div
                    className="weld-banner__note"
                    data-aos="fade"
                    data-aos-delay="1000"
                    dangerouslySetInnerHTML={{
                      __html:
                          data.warning + `<a target='_blanck' href="${data.link_in_text}">${' ' + data.text_url}</a>`
                    }}
                />
            )}
          </div>

          {width <= MEDIA.M1024 && (
              <div
                  className="weld-banner__content app__aos-mobile-disable"
                  data-aos="fade-up"
                  data-aos-delay="900"
            >
              {variables.weldmoney_link?.value && (
                  <div className="weld-banner__actions">
                    <AppButton
                        className="app-button--fill-3 weld-banner__btn"
                        href={variables.weldmoney_link.value}
                        target="_blank"
                    >
                      {t("actions.openCryptoCardShort")}
                    </AppButton>
                  </div>
              )}
                {/*<div*/}
                {/*  className="weld-banner__text"*/}
                {/*  dangerouslySetInnerHTML={{*/}
                {/*    __html: data.description,*/}
                {/*  }}*/}
                {/*/>*/}
              </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default WeldBanner;
